@tailwind base;
@tailwind components;
@tailwind utilities;
:root {
  background-color: rgba(150, 150, 150, 0.075) !important;
  --goround: rgba(10, 80, 150, 1);
}

.backgroundVideo {
  position: relative;
  width: 65%;
  margin: auto;
  height: 0;
  padding-top: 36.5625%;
}

.backgroundVideo > div:not(.content) {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.title {
  top: 2vw;
  font-size: 2.8vw;
}

.backgroundVideo h1 {
  position: absolute;
  top: -30%;
  width: 100%;
  text-align: center;
  z-index: 3000;
}
video::-webkit-media-controls-timeline {
  background-color: var(--goround);
  padding-bottom: 0;
  margin-bottom: 20px;
}
.backgroundVideo video {
  width: 65vw;
  position: absolute;
  top: 0;
  height: 36.5625vw;
}

.backgroundVideo video[poster] {
  object-fit: cover;
  object-position: center;
}

.css-1s2u09g-control {
  height: 100% !important;
  border-radius: 10px !important;
}
.App-background {
  background-color: rgba(150, 150, 150, 0.075) !important;
}
.App {
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.highlight {
  /* color: red; */
  background-color: rgba(30, 100, 200, 0.1) !important;
  width: 100%;
  height: 100%;
  justify-content: center;
  /* border-radius:300vw; */
  display: flex;
}
.backdrop {
  backdrop-filter: blur(5px) contrast(0.1);
}
.sideNav {
  display: flex;
  height: 100vh;
  position: -webkit-sticky !important;
  position: sticky;
  top: 0;
  right: 0;
}
.topNav {
  display: flex;
  position: -webkit-sticky !important;
  top: 0;
}
.react-calendar__tile
  react-calendar__tile--active
  react-calendar__tile--range
  react-calendar__tile--rangeStart
  react-calendar__tile--rangeEnd
  react-calendar__tile--rangeBothEnds
  react-calendar__month-view__days__day {
  background-color: blue !important;
}
.modal-content {
  justify-content: center !important;
  width: fit-content !important;
}
@keyframes fadeBackground {
  from {
    opacity: 0;
    scale: 0;
  }
  to {
    opacity: 1;
    scale: 1;
  }
}
.action-transition {
  animation: fadeBackground 0.4s;
}
.fixedElement {
  position: fixed;
  bottom: 10%;
  right: calc(50% - 16rem);
  /* 16rem is the width of the side navigation bar */
}
.stickyElement {
  position: -webkit-sticky !important;
  position: sticky !important;
  bottom: 10%;
  width: fit-content;
  right: 10%;

  background-color: red !important;
  /* padding:10px; */
}
.stickyElement div {
  /* right: 50%; */
  /* left: 50%; */
}
.react-calendar {
  width: 70% !important;
  /* max-width: 100%; */
  background-color: #fff;
  color: rgba(10, 80, 150, 1);
  border-radius: 8px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  line-height: 1.125em !important;
}

.react-calendar__navigation button {
  color: rgba(10, 80, 150, 1);
  min-width: 44px;
  background: none;
  font-size: 16px;
  margin-top: 8px;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #f8f8fa;
}
.react-calendar__navigation button[disabled] {
  background-color: #f1f2;
}
abbr[title] {
  text-decoration: none;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  /* background: rgba(10,80,150,1) !important; */
  background: linear-gradient(
    336deg,
    rgba(5, 95, 149, 1) 0%,
    rgba(9, 120, 121, 1) 100%,
    rgba(0, 255, 252, 1) 100%
  ) !important;

  color: white !important;
  border-radius: 6px;
  border-color: black;
  border-style: inner;
  border-width: 10px !important;
}
.react-calendar__tile--now {
  background: rgba(10, 80, 150, 1);
  border-radius: 6px;
  font-weight: bold;
  color: rgba(10, 80, 150, 1);
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: rgba(10, 80, 150, 1);
  border-radius: 6px;
  font-weight: bold;
  color: rgba(10, 80, 150, 1);
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #f8f8fa;
}
.react-calendar__tile--active {
  background: linear-gradient(
    336deg,
    rgba(5, 95, 149, 1) 0%,
    rgba(9, 120, 121, 1) 100%,
    rgba(0, 255, 252, 1) 100%
  ) !important;
  border-radius: 6px;
  font-weight: bold;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: linear-gradient(
    336deg,
    rgba(5, 95, 149, 1) 0%,
    rgba(9, 120, 121, 1) 100%,
    rgba(0, 255, 252, 1) 100%
  ) !important;
  color: white;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #f8f8fa;
}
.react-calendar__tile--range {
  background: #f8f8fa;
  color: rgba(10, 80, 150, 1);
  border-radius: 0;
}
.react-calendar__tile--rangeStart {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background: rgba(10, 80, 150, 1);
  color: white;
}
.react-calendar__month-view__weekdays__weekday abbr[title] {
  text-decoration: none !important;
  cursor: default !important;
}
.react-calendar__tile--rangeEnd {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background: rgba(10, 80, 150, 1);
  color: white;
}
.react-calendar {
  border-style: unset !important;
}
@layer components {
  .highlight {
    @apply flex justify-center;
  }
  .react-calendar__tile {
    @apply flex mx-3;
  }
  .single-notification {
    @apply flex shadow-lg p-2 gap-2 items-center justify-between m-3 w-full;
  }
  .notification {
    @apply w-2/4 right-5 absolute h-fit rounded bg-white;
  }
  .input-primary {
    @apply w-11/12 h-8 border-collapse shadow-lg m-3 rounded-2xl p-6 text-goround;
  }
  .css-1s2u09g-control {
    @apply rounded-2xl;
  }
  .input-primary-large {
    @apply w-11/12 h-24 border-collapse shadow-lg m-3 rounded-2xl p-8 text-goround text-2xl;
  }
  .btn-primary {
    @apply bg-goround rounded-lg p-4 text-white;
  }
  .card-primary {
    @apply shadow-lg rounded-md text-goround flex-wrap p-1;
  }
  .article-card {
    @apply shadow-xl hover:cursor-pointer hover:animate-pulse flex-row flex gap-3 rounded-xl m-3 text-goround p-6;
  }
}
.bar {
  background-color: transparent;
}
.bar.active {
  background-color: white !important;
}

.bi-eye-slash {
  width: 25px;
  height: 25px;
  position: absolute;
  z-index: 200;
  top: 28px;
  right: 10px;
  cursor: pointer;
}
.input-group-addon i {
  margin-left: -30px;
  cursor: pointer;
  z-index: 200;
  position: absolute;
  font-size: large;
  color: #6c757d;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
